<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12">
        <resume-table
          :edit="false"
          :report="report"
          :loading="loading"
          @export="prepareDownload"
        />
      </div>
    </div>

    <div
      class="row"
      v-show="hasObservations"
    >
      <div class="flex xs12">
        <va-card :title="$t('reports.inputs.observations')">
          <p>{{ report.observations }}</p>
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const ResumeTable = () => import(/* webpackPrefetch: true */ './Table')

export default {
  name: 'country-reports-view',
  components: {
    ResumeTable,
  },
  data () {
    return {
      error: false,
      loading: false,
      report: {},
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    hasObservations () {
      return this.report.observations && this.report.observations.length > 0
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    proyectionApproval (type) {
      switch (type) {
        case -1:
          type = 'tables.status.rejected'
          break
        case 1:
          type = 'tables.status.approved'
          break
        default:
          type = 'tables.status.unreviewed'
          break
      }
      type = this.$t(type)

      return type
    },
    routeBuilder (id) {
      return `reports/countries/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const reportId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(reportId))
      } catch (err) {
        // console.log('Error fetching country data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.report = u.data.data
    },
    async prepareDownload () {
      const d = {
        type: 'country',
        date: this.report.proyection_date,
        id: this.report.country_id,
      }

      const prepareRoute = 'proyections/monthly?export=xlsx'
      const downloadRoute = 'proyections/download/'

      return this.downloadFile(d, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
